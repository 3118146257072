<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Poli</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwPoli">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>nmpoli</th>
                                <th>nmsubspesialis</th>
                                <th>kdsubspesialis</th>
                                <th>kdpoli</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataPoli" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.nmpoli||"-"}}</td>
                                <td>{{v.nmsubspesialis||"-"}}</td>
                                <td>{{v.kdsubspesialis||"-"}}</td>
                                <td>{{v.kdpoli||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Dokter</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwDokter">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>nama dokter</th>
                                <th>kode dokter</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataDokter" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.namadokter||"-"}}</td>
                                <td>{{v.kodedokter||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">CEK Poli FP</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row pt-2" v-if="shwPoliFP">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>nmpoli</th>
                                <th>nmsubspesialis</th>
                                <th>kdsubspesialis</th>
                                <th>kdpoli</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in dataPoliFP" :key="v.kode">
                                <td>{{k+1}}</td>
                                <td>{{v.namapoli||"-"}}</td>
                                <td>{{v.kodesubspesialis||"-"}}</td>
                                <td>{{v.kodesubspesialis||"-"}}</td>
                                <td>{{v.kodepoli||"-"}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Jadwal Dokter</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-8" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpJadwalDokter" class="form-control" 
                            placeholder="Ketik Kode Poli" />
                            </div>
                        </div>
                    
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="tglJadwalDokter">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-auto">
                            <button @click="searchJadwalDokter" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetJadwalDokter">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwJadwalDokter">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td>no</td>                                    
                                        <td>hari</td>
                                        <td>jadwal</td>
                                        <td>kapasitaspasien</td>
                                        <td>kodedokter</td>
                                        <td>kodepoli</td>
                                        <td>kodesubspesialis</td>
                                        <td>libur</td>
                                        <td>namadokter</td>
                                        <td>namahari</td>
                                        <td>namapoli</td>
                                        <td>namasubspesialis</td>
                                    </tr>
                                    <tr v-for="(v,k) in dataJadwalDokter" :key="k">
                                        <td>{{k+1}}</td>
                                        <td>{{v.hari||"-"}}</td>
                                        <td>{{v.jadwal||"-"}}</td>
                                        <td>{{v.kapasitaspasien||"-"}}</td>
                                        <td>{{v.kodedokter||"-"}}</td>
                                        <td>{{v.kodepoli||"-"}}</td>
                                        <td>{{v.kodesubspesialis||"-"}}</td>
                                        <td>{{v.libur||"-"}}</td>
                                        <td>{{v.namadokter||"-"}}</td>
                                        <td>{{v.namahari||"-"}}</td>
                                        <td>{{v.namapoli||"-"}}</td>
                                        <td>{{v.namasubspesialis||"-"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek Pasien Finger Print</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-8" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpPasienFP" class="form-control" 
                            placeholder="Ketik Kode Poli" />
                            </div>
                        </div>
                    
                        <div class="col-md-4">
                            <div class="form-group mb-0">
                                <v-select placeholder="Pilih Type" v-model="jnsPasienFP" :options="Config.mr.jenisNOKA" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                        </div>

                        <div class="col-md-auto">
                            <button @click="searchPasienFP" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetPasienFP">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwPasienFP">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="25%">
                                            <div class="result_tab">
                                            <h4>nomor kartu</h4>
                                            <p>{{dataPasienFP.nomorkartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="25%">
                                            <div class="result_tab">
                                            <h4>nik</h4>
                                            <p>{{dataPasienFP.nik||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="25%">
                                            <div class="result_tab">
                                            <h4>tgllahir</h4>
                                            <p>{{dataPasienFP.tgllahir||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="25%">
                                            <div class="result_tab">
                                            <h4>daftarfp</h4>
                                            <p>{{dataPasienFP.daftarfp||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'

export default {
  extends: GlobalVue,
  data() {
    return {
        shwPoli: false,  
        dataPoli: {},
        
        shwDokter: false,  
        dataDokter: {},
        
        shwPoliFP: false,  
        dataPoliFP: {},

        inpJadwalDokter: "",
        tglJadwalDokter: null,
        shwJadwalDokter: false,  
        dataJadwalDokter: {},

        
        inpPasienFP: "",
        jnsPasienFP: null,
        shwPasienFP: false,  
        dataPasienFP: {},
    }
  },
  components:{
    Datepicker,
  },
  methods: {
    searchPoli(){
        this.loadingOverlay = true
        let data = {
            type : "search-poli",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail" || resp.response == "NO DATA"){
                this.loadingOverlay = false
                this.shwPoli = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Poli Tidak Ditemukan'
                })
            }

            this.loadingOverlay = false
            this.dataPoli = resp.response
            console.log(this.dataPoli)
            this.shwPoli = true
        }).catch(err=>{
            this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Poli Tidak Ditemukan'
            })
        })
    },
    searchDokter(){
        this.loadingOverlay = true
        let data = {
            type : "search-dokter",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail" || resp.response == "NO DATA"){
                this.loadingOverlay = false
                this.shwDokter = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Dokter Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataDokter = resp.response
            this.shwDokter = true
        }).catch(err=>{
            this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Dokter Tidak Ditemukan'
            })
        })
    },

    searchPoliFP(){
        this.loadingOverlay = true
        let data = {
            type : "search-poli-fp",
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            if(resp.status == "fail" || resp.response == "NO DATA"){
                this.loadingOverlay = false
                this.shwPoliFP = false

                return this.$swal({
                    icon: 'error',
                    text: 'Data Poli Finger Print Tidak Ditemukan'
                })
            }
            this.loadingOverlay = false
            this.dataPoliFP = resp.response
            this.shwPoliFP = true
        }).catch(err=>{
            this.loadingOverlay = false
            return this.$swal({
                icon: 'error',
                text: 'Data Poli Finger Print Tidak Ditemukan'
            })
        })
    },

    searchJadwalDokter(){
        if(this.inpJadwalDokter){
            this.loadingOverlay = true
            let data = {
                type : "search-jadwal-dokter",
                search : this.inpJadwalDokter,
                tanggal: this.tglJadwalDokter
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwJadwalDokter = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Jadwal Dokter Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataJadwalDokter = resp.response
                this.shwJadwalDokter = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Jadwal Dokter Tidak Ditemukan'
                })
            })
        }
    },
    resetJadwalDokter(){
        this.inpJadwalDokter = null
        this.shwJadwalDokter = false
        this.dataJadwalDokter = {}
    },

    searchPasienFP(){
        if(this.inpPasienFP){
            this.loadingOverlay = true
            let data = {
                type : "search-fp-identitas",
                search : this.inpPasienFP,
                jenis: this.jnsPasienFP
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwPasienFP = false
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Ada',
                        text: 'Data Pasien Finger Print Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataPasienFP = resp.response
                this.shwPasienFP = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Pasien Finger Print Tidak Ditemukan'
                })
            })
        }
    },
    resetPasienFP(){
        this.inpPasienFP = null
        this.shwPasienFP = false
        this.dataPasienFP = {}
    },
  },
  mounted() {
    this.searchPoli()
    this.searchDokter()
    this.searchPoliFP()


  },
}

</script>